import 'lazysizes'
import '../fonts/sbol/styles.css'
import './utils/htmx'
import 'htmx-ext-ws'

import {initBootstrap, loadComponents} from "./bootstrap.js";


document.addEventListener("DOMContentLoaded", function () {
    loadComponents();
    initBootstrap();
})