import {listen} from "../utils";

const Selector = {
    NUM_SELECTED: '[data-num-selected]',
    SELECT_ALL: '[data-select-all]',
    SELECTABLE: '[data-selectable]',
    SELECTION_CONTAINER: '.card,tr',
    SELECTION_REQUIRED: '[data-selected]',
    CLEAR_SELECTION: '[data-clear-selection]'
}

listen('change', Selector.SELECTABLE, (event, target) => {
    if (target.type === 'radio') {
        target.form.querySelectorAll(Selector.SELECTION_CONTAINER).forEach((e) => e.classList.remove('active'))
    }
    target.closest(Selector.SELECTION_CONTAINER)?.classList.toggle('active', target.checked)
    target.form?.querySelectorAll(Selector.SELECTION_REQUIRED).forEach((e) => e.disabled = !target.checked)
})

// https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage
export function storeSelection(forms) {
    forms.forEach((form) => {
        const storageId = form.dataset.storageId
        const content = sessionStorage.getItem(storageId)
        const selectionFieldName = form.dataset.selectionField
        const selectionFieldValues = new Set(content ? JSON.parse(content) : []);
        const numSelected = form.querySelector(Selector.NUM_SELECTED)
        const clearButton = form.querySelector(Selector.CLEAR_SELECTION)
        const hiddenInputs = [];
        const inputs = [...form.querySelectorAll(`input[type="checkbox"][name="${selectionFieldName}"]`)]

        const handleCheck = (inputs) => {
            inputs.forEach((input) => {
                input.closest(Selector.SELECTION_CONTAINER)?.classList.toggle('active', input.checked)
                input.checked ? selectionFieldValues.add(input.value) : selectionFieldValues.delete(input.value)
            })
            if (numSelected) {
                numSelected.textContent = `${selectionFieldValues.size}`
            }
            clearButton.classList.toggle('d-none', selectionFieldValues.size === 0)
            form.querySelectorAll(Selector.SELECTION_REQUIRED).forEach((el) => el.disabled = selectionFieldValues.size === 0)
            sessionStorage.setItem(storageId, JSON.stringify([...selectionFieldValues]))
        }

        const handleClear = () => {
            form.querySelectorAll(Selector.SELECTION_CONTAINER).forEach((el) => el.classList.remove('active'))
            if (numSelected) {
                numSelected.textContent = '0'
            }
            if (clearButton) {
                clearButton.classList.add('d-none')
                form.querySelectorAll(Selector.SELECTION_REQUIRED).forEach((el) => el.disabled = true)
                inputs.forEach((el) => el.checked = false)
                selectionFieldValues.clear()
                hiddenInputs.forEach((el) => el?.remove())
                sessionStorage.removeItem(storageId)
            }
        }

        const appendHiddenInput = (name, value) => {
            const input = document.createElement('input')
            input.type = 'hidden'
            input.name = name
            input.value = value
            form.appendChild(input)
            return input
        }

        if (numSelected) {
            numSelected.textContent = `${selectionFieldValues.size}`
        }

        if (clearButton) {
            clearButton.classList.toggle('d-none', selectionFieldValues.size === 0)
            form.querySelectorAll(Selector.SELECTION_REQUIRED).forEach((el) => el.disabled = selectionFieldValues.size === 0)
            selectionFieldValues.forEach((value) => {
                if (!inputs.some((el) => el.value === value)) {
                    const hiddenInput = appendHiddenInput(selectionFieldName, value)
                    hiddenInputs.push(hiddenInput)
                }
            })
        }

        inputs.forEach((input) => {
            input.checked = selectionFieldValues.has(input.value)
            input.closest(Selector.SELECTION_CONTAINER)?.classList.toggle('active', input.checked)
            input.addEventListener('change', () => handleCheck([input]))
        });

        form.querySelectorAll(Selector.SELECT_ALL).forEach((button) => {
            button.addEventListener('click', () => {
                inputs.forEach((input) => input.checked = button.checked)
                handleCheck(inputs)
            })
        })

        form.querySelectorAll('button[type="reset"], button[hx-post]').forEach((button) => {
            button.addEventListener('click', () => handleClear())
        })
        listen('click', Selector.CLEAR_SELECTION, () => handleClear())
    })
}
